import React, { useState, useRef, useEffect } from 'react';
import AnimationWrapper from "@Layout/animationWrapper/AnimationWrapper";
import styles from './Contact.module.scss';
import axios from "axios";
import { motion } from "framer-motion";
import PacmanLoader from "react-spinners/PacmanLoader";
import heroImage from "@Assets/images/circle3.png";
import Hero from "@Components/hero/Hero";

const toggleCaptchaBadge = (show: boolean) => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (badge && badge instanceof HTMLElement) {
        badge.style.visibility = show ? 'visible' : 'hidden';
    }
};

const Contact = () => {

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>();
    const [formValid, setFormValid] = useState<boolean>();
    const [formSuccess, setFormSuccess] = useState<boolean>();
    const [formError, setFormError] = useState<boolean>();
    const [buttonMotion, setButtonMotion] = useState<object>();

    const [trap, setTrap] = useState<string>();
    const [captchaToken, setCaptchaToken] = useState<string>('');

    const nameInput = useRef<HTMLDivElement>(null);
    const emailInput = useRef<HTMLDivElement>(null);
    const messageInput = useRef<HTMLDivElement>(null);
    const trapInput = useRef<HTMLInputElement>(null);

    const captchaLoad = () => {

        grecaptcha.ready(() => {
            grecaptcha
                .execute(process.env.RECAPTCHA_KEY, { action: "contactPage" })
                .then((token: string) => setCaptchaToken(token))
        })

    }

    useEffect(() => {

        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_KEY}`;
        script.addEventListener("load", captchaLoad);
        document.body.appendChild(script);

        toggleCaptchaBadge(true);
        return () => toggleCaptchaBadge(false);

    }, [])

    const clearError = (inputRef: React.RefObject<HTMLDivElement>) => {
        if(inputRef.current) inputRef.current.classList.remove(styles.error)
    }

    const validateForm = async() => {

        let valid = true;

        if(trap){
            valid = false;

//      I T ' S   A   B O T   T R A P
//      . . . . . . . . . . . . . . . . _,,,--~~~~~~~~--,_
//      . . . . . . . . . . . . . . ,-' : : : :::: :::: :: : : : : :º '-,
//      . . . . . . . . . . . . .,-' :: : : :::: :::: :::: :::: : : :o : '-,
//      . . . . . . . . . . . ,-' :: ::: :: : : :: :::: :::: :: : : : : :O '-,
//      . . . . . . . . . .,-' : :: :: :: :: :: : : : : : , : : :º :::: :::: ::';
//      . . . . . . . . .,-' / / : :: :: :: :: : : :::: :::-, ;; ;; ;; ;; ;; ;; ;\
//      . . . . . . . . /,-',' :: : : : : : : : : :: :: :: : '-, ;; ;; ;; ;; ;; ;;|
//      . . . . . . . /,',-' :: :: :: :: :: :: :: : ::_,-~~,_'-, ;; ;; ;; ;; |
//      . . . . . _/ :,' :/ :: :: :: : : :: :: _,-'/ : ,-';'-'''''~-, ;; ;; ;;,'
//      . . . ,-' / : : : : : : ,-''' : : :,--'' :|| /,-'-'--'''__,''' \ ;; ;,-'/
//      . . . \ :/,, : : : _,-' --,,_ : : \ :\ ||/ /,-'-'x### ::\ \ ;;/
//      . . . . \/ /---'''' : \ #\ : :\ : : \ :\ \| | : (O##º : :/ /-''
//      . . . . /,'____ : :\ '-#\ : \, : :\ :\ \ \ : '-,___,-',-`-,,
//      . . . . ' ) : : : :''''--,,--,,,,,,¯ \ \ :: ::--,,_''-,,'''¯ :'- :'-,
//      . . . . .) : : : : : : ,, : ''''~~~~' \ :: :: :: :'''''¯ :: ,-' :,/\
//      . . . . .\,/ /|\\| | :/ / : : : : : : : ,'-, :: :: :: :: ::,--'' :,-' \ \
//      . . . . .\\'|\\ \|/ '/ / :: :_--,, : , | )'; :: :: :: :,-'' : ,-' : : :\ \,
//      . . . ./¯ :| \ |\ : |/\ :: ::----, :\/ :|/ :: :: ,-'' : :,-' : : : : : : ''-,,
//      . . ..| : : :/ ''-(, :: :: :: '''''~,,,,,'' :: ,-'' : :,-' : : : : : : : : :,-'''\\
//      . ,-' : : : | : : '') : : :¯''''~-,: : ,--''' : :,-'' : : : : : : : : : ,-' :¯'''''-,_ .
//      ./ : : : : :'-, :: | :: :: :: _,,-''''¯ : ,--'' : : : : : : : : : : : / : : : : : : :''-,
//      / : : : : : -, :¯'''''''''''¯ : : _,,-~'' : : : : : : : : : : : : : :| : : : : : : : : :
//      : : : : : : : :¯''~~~~~~''' : : : : : : : : : : : : : : : : : : | : : : : : : : : :)

        }

        if(!name && nameInput.current) {
            nameInput.current.classList.add(styles.error)
            valid = false;
        }

        if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email) && emailInput.current){
            emailInput.current.classList.add(styles.error);
            valid = false;
        }

        if(!message && messageInput.current) {
            messageInput.current.classList.add(styles.error);
            valid = false;
        }

        if(valid && captchaToken){

            const params = new URLSearchParams();
            params.append('token', captchaToken);

            try {

                const response = await axios({
                    method: 'post',
                    url: `${process.env.FULLSTACK_BACKEND}/verify`,
                    headers: {'Content-Type' : 'application/x-www-form-urlencoded'},
                    data: params,
                    withCredentials: true,
                })

                if(!response.data.verified.success) valid = false;

            } catch(error: any) {
                setFormError(true);
                console.log(Object.keys(error), error.message);
                valid = false;
            }

        }

        setFormValid(valid);
        return valid;

    }

    const submitForm = async () => {

        let continueSubmission = await validateForm();

        if(continueSubmission){

            setTimeout(()=>{

                //delay for button fade animation
                setLoading(true)

                const params = new URLSearchParams();
                params.append('name', name);
                params.append('email', email);
                params.append('message', message);

                axios({
                    method: 'post',
                    url: `${process.env.FULLSTACK_BACKEND}/send`,
                    headers: {'Content-Type' : 'application/x-www-form-urlencoded'},
                    data: params
                }).then(()=>{
                    //delay for pacman animation
                    setTimeout(()=>{
                        setFormSuccess(true);
                    },2000)
                }).catch((error)=>{
                    //delay for pacman animation
                    setTimeout(()=>{
                        setFormError(true);
                    },2000)
                }).finally(()=>{
                    //delay for pacman animation
                    setTimeout(()=>{
                        setLoading(false)
                    },2000)
                })

            }, 1000)

        }

    }

    useEffect(()=>{
        if(formValid)  {
            setButtonMotion({
                scaleX: 0.02,
                scaleY: 0.2,
                color: '#0a5075',
                transitionEnd: {
                    display: "none",
                },
            })
        }
    }, [formValid])

    const getButton = () => {

        if(loading){
            return <PacmanLoader color={'#0a5075'} loading={true} size={25} margin={2} />
        } else {

            return (
                <motion.div animate={buttonMotion} transition={{ duration: 1 }} style={{originX: 1}}>
                    <button className={buttonMotion ? styles.buttonAnimating : null} onClick={()=>submitForm()}>Submit</button>
                </motion.div>
            )

        }

    }

    const getView = () => {

        if(formSuccess){

            return (
                <div className={styles.contactPage}>
                    <div className={styles.contactWrapper}>
                        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} >
                            <p>Thank you for reaching out. I will try to get back to you shortly.</p>
                        </motion.div>
                    </div>
                </div>
            )

        } else if(formError){

            return (
                <div className={styles.contactPage}>
                    <div className={styles.contactWrapper}>
                        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} >
                            <p>There was an error with our service. Please try again later.</p>
                        </motion.div>
                    </div>
                </div>
            )

        } else {

            return (
                <div className={styles.contactPage}>
                    <div className={styles.contactWrapper}>

                        <div className={styles.inputWrapper} ref={nameInput as React.RefObject<HTMLDivElement>}>
                            <label>Enter your name</label>
                            <input type={'text'} name={'name'} maxLength={60}
                                   onChange={(e)=>setName(e.currentTarget.value)}
                                   onFocus={()=>clearError(nameInput)}/>
                        </div>

                        <div className={styles.inputWrapper} ref={emailInput as React.RefObject<HTMLDivElement>}>
                            <label>Enter your email</label>
                            <input type={'text'} name={'email'} maxLength={60}
                                   onChange={(e)=>setEmail(e.currentTarget.value)}
                                   onFocus={()=>clearError(emailInput)}/>
                        </div>

                        <div className={styles.inputWrapper} ref={messageInput as React.RefObject<HTMLDivElement>}>
                            <label>Enter a message</label>
                            <textarea name={'message'} maxLength={200} rows={5}
                                      onChange={(e)=>setMessage(e.currentTarget.value)}
                                      onFocus={()=>clearError(messageInput)}/>
                        </div>

                        <input type={'checkbox'} name={'trap'}
                               aria-label="Do not check this box if you are human"
                               className={styles.contactTrap}
                               ref={trapInput}
                               onChange={(e)=>setTrap(e.currentTarget.value)}/>
                        <div className={styles.buttonRow}>
                            <div/>
                            {getButton()}
                        </div>

                    </div>
                </div>
            )

        }

    }

    return (
        <AnimationWrapper>

            <Hero frame={heroImage} home={true}>
                {/*<h1 data-aos={'fade-up'} data-aos-delay="200">The Myth, The <i>Legend</i></h1>*/}
                <h2 data-aos={'fade-up'} data-aos-delay="400">Hello!</h2>
                <p data-aos={'fade-up'} data-aos-delay="600"  data-aos-offset="0">
                    I love to hear from people about the type of projects and unique experiences they have worked on or looking to develop. If you are looking for a developer for a project or just a consultation, just reach out and lets see what happens!
                </p>
            </Hero>

            {getView()}

            <div className="g-recaptcha"
                data-sitekey={process.env.RECAPTCHA_KEY}
                data-size="invisible"/>

        </AnimationWrapper>
    )
};

export default Contact
