import React from 'react';
import { motion } from "framer-motion";

type AnimationWrapperProps = {
    children: React.ReactNode
}

const AnimationWrapper = ({children}: AnimationWrapperProps) => {

    const pageMotion = {
        initial: { opacity: 0, x: -100 },
        animate: { opacity: 1, x: 0, transition: { duration: 0.5 } },
        exit: { opacity: 0, x: 100, transition: { duration: 0.5 } }
    };

    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageMotion}
            data-testid={'wrapper'}
        >
            {children}
        </motion.div>
    )
};

export default AnimationWrapper
