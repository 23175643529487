import OriginalByDesign from "@Pages/portfolio/originalByDesign/OriginalByDesign";
import SpaceJam from "@Pages/portfolio/spaceJam/SpaceJam";
// import JeldWen from "@Pages/portfolio/jeldWen/JeldWen";
// import Lacantina from "@Pages/portfolio/lacantina/Lacantina";
import OpenForBusiness from "@Pages/portfolio/openForBusiness/OpenForBusiness";
import FarmFresh from "@Pages/portfolio/farmFresh/FarmFresh";
import WordGame from "@Pages/portfolio/wordGame/WordGame";
import ServiceAI from "@Pages/portfolio/serviceAI/ServiceAI";
// import JeremyIngle from "@Pages/portfolio/jeremyIngle/JeremyIngle";
import CmdAgency from "@Pages/portfolio/cmdAgency/CmdAgency";
import BeatBox from "@Pages/portfolio/beatBox/BeatBox";

const portfolioPages = [
  {
    key: "msft-obd",
    link: "/portfolio/msft-obd",
    label: 'Microsoft\'s Original by Design',
    title: "Microsoft's Original by&nbsp;Design",
    video:'assets/portfolios/obd/OBD-preview-trimmed.mp4',
    component: OriginalByDesign,
    demo: false
  },
  {
    key: "msft-sj",
    link: "/portfolio/msft-sj",
    label: 'Microsoft & Space Jam',
    title: "Microsoft and Space Jam game experience&nbsp;campaign",
    image:'assets/portfolios/sj/screen-desktop.png',
    component: SpaceJam,
    demo: false
  },
  // {
  //   key: "jw",
  //   link: "/portfolio/jw",
  //   label: '',
  //   title: "Jeld-Wen Windows and Doors CMS Ecosystem",
  //   component: JeldWen,
    // demo: false
  // },
  // {
  //   key: "lc",
  //   link: "/portfolio/lc",
  //   label: '',
  //   title: "LaCantina Windows and Doors",
  //   component: Lacantina,
    // demo: false
  // },
  {
    key: "ofb",
    link: "/portfolio/ofb",
    label: 'Open For Business',
    title: "CMD's Open for Business&nbsp;Initiative",
    image:'assets/portfolios/ofb/screen-desktop.png',
    component: OpenForBusiness,
    demo: false
  },
  {
    key: "ff",
    link: "/portfolio/ff",
    label: 'Farm Fresh',
    title: "Farm Fresh Farmers Market&nbsp;Finder",
    image:'assets/portfolios/farmFresh/screen-desktop.png',
    component: FarmFresh,
    demo: false
  },
  {
    key: "ai",
    link: "/portfolio/ai",
    label: 'Magicbox AI',
    title: "CMD / Intel cognitive services AI&nbsp;tool",
    image:'assets/portfolios/ai/screen-desktop.png',
    component: ServiceAI,
    demo: false
  },
  {
    key: "cmd",
    link: "/portfolio/cmd",
    label: 'CMD Agency',
    title: "CMD Agency",
    image:'assets/portfolios/cmd/screen-desktop.png',
    component: CmdAgency,
    demo: false
  },
  
  // {
  //   key: "ji",
  //   link: "/portfolio/ji",
  //   label: 'This Site!',
  //   title: "This Site! A compilation of greatness!",
  //   image:'assets/portfolios/ji/screen-desktop.png',
  //   component: JeremyIngle,
    // demo: false
  // }

  {
    key: "st",
    link: "/portfolio/st",
    label: '<i>Strange</i> Word Game',
    title: "Word game clone based on a popular, yet <i>strange</i> television&nbsp;series",
    image:'assets/portfolios/word/screen.png',
    component: WordGame,
    demo: true
  },
  {
    key: "beatbox",
    link: "/portfolio/beatbox",
    label: 'BeatBoxJS',
    title: "Keyboard based beatbox, built for fun",
    image:'assets/portfolios/beatbox/screen.png',
    component: BeatBox,
    demo: true
  },
]

export default portfolioPages;