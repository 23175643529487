import styles from './Footer.module.scss';

const Footer = () => {

    const getYear = () => {
        return new Date().getFullYear();
    }

    return (
        <footer className={styles.footer}>
            <div/>
            <div>©{getYear()} All Rights Reserved. JeremyIngle.com</div>
        </footer>
    )
};

export default Footer
