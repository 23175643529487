import PortfolioLayout from "@Layout/portfolioLayout/PortfolioLayout";
import PortfolioAside from "@Components/portfolioAside/PortfolioAside";
import styles from './OpenForBusiness.module.scss';

const OpenForBusiness = () => {

    return (
        <PortfolioLayout page={'ofb'}>
            <section>
                <h3>Project Description</h3>
                <p>The Open for Business initiative was launched to lend a hand during Covid.</p>
                <p>The mission is simple: connect brands with experienced storyteller volunteers to help them communicate to the world that they’re open for business.</p>
                <h4 className={styles.label}>Recently Rebranded</h4>
                <div className={styles.image}><img src={'/assets/portfolios/ofb/screen-desktop.png'} alt={'Home Landing Rebrand'}/></div>
                <p>The core stack of this website uses React, a small custom PHP Api, and Directus as our database. All administrative tasks were built off Hubspot workflows that communicated with our API layer to move users through the process of connecting, approving and uploading their content to the site.</p>
                <h4 className={styles.label}>Hubspot Workflow</h4>
                <div className={styles.image}><img className={styles.workflow} src={'/assets/portfolios/ofb/workflow.png'} alt={'Hubspot Workflow'}/></div>
                <h4 className={styles.label}>Full Site Layout (Original Design)</h4>
                <div className={styles.image}><img src={'/assets/portfolios/ofb/screen.png'} alt={'Home Landing Rebrand'}/></div>
            </section>
            <PortfolioAside link={{url: 'https://weareopenforbusiness.org/', text: 'Open for Business'}} list={['React','Laravel','Directus Cloud Database','Hubspot Workflows and Emails', 'Vimeo API Integration']}/>
        </PortfolioLayout>
    )
};

export default OpenForBusiness
