import PortfolioLayout from "@Layout/portfolioLayout/PortfolioLayout";
import PortfolioAside from "@Components/portfolioAside/PortfolioAside";
import styles from "@Pages/portfolio/beatBox/BeatBox.module.scss";

const BeatBox = () => {

    return (
        <PortfolioLayout page={'beatbox'}>
            <section>
                <h3>Project Description</h3>
                <p>A fun demo built utilizing the Web Audio API and Audio Context. I created two sets of sounds, the first was a set of MP3's loaded into an array buffer for fast playback and creating a hashmap of keys to files to optimize performance and improve playback speed. I created a second set of sounds using the Audio Context's oscillator and gain functions for an 8-bit array of sounds.</p>
                <p>Try it out, have fun!</p>
                <h4 className={styles.label}>Game Demo</h4>
                <div className={styles.image}>
                    <a href={'https://beatboxjs-cd714.web.app/'} target="_blank" rel="noreferrer">
                    <img src={'/assets/portfolios/beatbox/screen.png'} alt={'BeatBoxJs web audio beatbox'}/>
                    </a>
                </div>
            </section>
            <PortfolioAside link={{url: 'https://beatboxjs-cd714.web.app/', text: 'BeatBoxJS'}} list={['React','Web Audio']}/>

        </PortfolioLayout>
    )
};

export default BeatBox
