import AnimationWrapper from "@Layout/animationWrapper/AnimationWrapper";
import Hero from "@Components/hero/Hero";
import heroImage from "@Assets/images/circle1.png";
import ContentWrapper from "@Components/contentWrapper/ContentWrapper";
import Title from "@Components/title/Title";
import styles from './About.module.scss';

const About = () => {

    return (
        <AnimationWrapper>

            <Hero frame={heroImage} home={true}>
                <h1 data-aos={'fade-up'} data-aos-delay="200">The Myth, The <i>Legend</i></h1>
                <h2 data-aos={'fade-up'} data-aos-delay="400">A Little About Me</h2>
                <p data-aos={'fade-up'} data-aos-delay="600"  data-aos-offset="0">
                        I designed my first website back in 2002. Over the years I have worked with several companies to write modern, performant, and maintainable code for a diverse array of client and internal projects.
                </p>
            </Hero>

            <ContentWrapper>
                <Title text={'Want to hear more?'} tag={'h3'}/>
                <p>In my current position at the creative marketing agency, CMD, I have had the opportunity to explore and demo many different technologies.</p>
                <p>Recent projects have included full custom React based CMS systems for Jeld-Wen Windows & Doors, advanced website experiences for social campaigns driven by Microsoft, and AI based tools using Azure Cognitive Services for Intel projects.</p>

                <Title text={'My Focus'} tag={'h3'}/>
                <p>I have had the privledge to work with a variety of different languages, platforms, frameworks, and content management systems</p>
                <div className={styles.bulletWrapper}>
                    <p>Responsive website design</p>
                    <p>UI/UX design, architecture and animations</p>
                    <p>HTML5 including audio, video and canvas</p>
                    <p>CSS3 including transforms and animations</p>
                    <p>Webpack, Sass, Babel build processes</p>
                    <p>React.js and similar frameworks</p>
                    <p>Node.js and backend API architectures</p>
                </div>

            </ContentWrapper>

        </AnimationWrapper>
    )
};

export default About
