import { Routes, Route, useLocation} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import styles from './Body.module.scss';

import Home from "@Pages/home/Home";
import About from "@Pages/about/About";
import Contact from "@Pages/contact/Contact";
import Info from "@Pages/info/Info";
import Portfolio from "@Pages/portfolio/Portfolio";
import portfolioPages from "@Pages/portfolio/Portfolio.pages";

const Body = () => {

    const location = useLocation();

    const buildPortfolioPages = () => {

        return portfolioPages.map((page)=>{
            let Component = page.component;
            return <Route key={page.key} path={page.link} element={<Component/>} />
        })

    }

    return (
        <div className={styles.body} data-testid={'body'}>
            <AnimatePresence exitBeforeEnter>
                <Routes location={location} key={location.pathname}>
                    <Route path={``} element={<Home/>} />
                    <Route path={`/about`} element={<About/>} />
                    <Route path={`/contact`} element={<Contact/>} />
                    <Route path={`/info`} element={<Info/>} />
                    <Route path={`/portfolio`} element={<Portfolio/>} />

                    {buildPortfolioPages()}

                </Routes>
            </AnimatePresence>
        </div>

    )
};

export default Body
