import PortfolioLayout from "@Layout/portfolioLayout/PortfolioLayout";
import PortfolioAside from "@Components/portfolioAside/PortfolioAside";
import styles from './FarmFresh.module.scss';

const FarmFresh = () => {

    return (
        <PortfolioLayout page={'ff'}>
            <section>
                <h3>Project Description</h3>
                <div className={styles.twoCol}>
                    <div className={styles.video}>
                        <video autoPlay playsInline muted loop controls>
                            <source src={'/assets/portfolios/farmFresh/interactive.mp4'} type="video/mp4" />
                        </video>
                    </div>
                    <div>
                        <p>The Farm Fresh Farmers Market Finder project is the equivalent of my TO-DO app for testing new technologies. I have written this application several times, first simply for the web, followed by an Angular application wrapped in Cordova for mobile deployment.</p>
                        <p>Most recently I updated the code base to React Native. My primary goal with the most recent code base was to share as much of the code base between the web based version and the React Native version. By creation definition files I exported custom components for commonly used elements like divs, images and text that would load the proper elements based on the code base. For example, the definition "IMG", imported by either system and used as &lt;IMG/&gt; would load an &lt;img/&gt; tag in web and an &lt;Image/&gt; tag in native.  </p>
                        <p>Following this pattern I was able to share the majority of my react based components and styles for both environments in a single repository.</p>
                    </div>
                </div>
            </section>

            <PortfolioAside link={{url: 'https://farmfreshmarketfinder.com', text: 'FarmFresh Market Finder'}} list={['React','React Native','Firebase Cloud Functions (API)','Google Maps/Directions']}/>

        </PortfolioLayout>

    )
};

export default FarmFresh
