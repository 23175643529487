import AnimationWrapper from "@Layout/animationWrapper/AnimationWrapper";
import heroImage from "@Assets/images/circle2.png";
import Hero from "@Components/hero/Hero";
import ContentWrapper from "@Components/contentWrapper/ContentWrapper";
import styles from './Info.module.scss';

const Info = () => {

    const careers = [
        {
            time:'December 2021 - Present',
            title:'Principal Software Engineer',
            location:'CMD',
            description:''
        },
        {
            time:'June 2015 - Present',
            title:'Senior Software Engineer',
            location:'CMD',
            description:'At CMD I worked on an array of many different project types, from extended custom CMS platform development to tight turn projects with massive online presence. I was tasked with leading several projects for high profile clients, creating application demos for client pitches and assisting other teams when needed.  I was able to dive into many new technologies including AI (using Azure services,) AR in React-Native, javascript animation and more. I excelled in an environment that demanded a higher than usual work velocity and was known as a go-to individual for project support.'
        },
        {
            time:'March 2014 - June 2015',
            title:'Software Engineer',
            location:'PointSource LLC',
            description:'I helped design and develop hybrid mobile applications in an Agile environment, working with server-side API’s using RESTful interfaces. I worked closely with UX Designers to create amazing, cutting-edge applications across a variety of industries including healthcare, insurance and retail.'
        },
        {
            time:'March 2012 - March 2104',
            title:'Software Engineer',
            location:'MedThink Inc',
            description:' I worked closely with a design and UX team to implement responsive websites for pharmaceutical clients. Our team also developed Learning Module Systems for training client sales teams as well as internal staff. We developed several hybrid applications for sales teams to use for client presentation as well as conference based informational applications.'
        },
        {
            time:'2002 - 2012',
            title:'Web Developer -> Sr. Front End Engineer',
            location:'8 Squared Inc, Merazon Health, Internet Direct Response',
            description:'From the beginning, I was a dedicated developer, tasked with website design and creation for 8 Squared Inc, whose focus was health products. As the portfolio of sites grew, the company was eventually acquired by Merazon Health.  I continued my position of site creation and maintenance of the existing portfolio. By 2007 the company had grown and needed dedicated advertising support. They hired Internet Direct Response to take over all online assets and advertising campaigns, who in turn hired me (remote) onto their team as a senior front-end engineer to continue my work.'
        },
    ]

    const buildContent = () => {
        return careers.map((job)=>{
            return (
                <div key={job.time}>
                    <div className={styles.time}><span className={styles.circle}/>{job.time}</div>
                    <h3 className={styles.title}>{job.title}</h3>
                    <div className={styles.location}>{job.location}</div>
                    <div className={styles.description}>{job.description}</div>
                </div>
            )
        })
    }

    return (
        <AnimationWrapper>
            <Hero frame={heroImage} home={true}>
                <h1 data-aos={'fade-up'} data-aos-delay="200">Who is this guy?</h1>
                <h2 data-aos={'fade-up'} data-aos-delay="400">Some Background</h2>
                <p data-aos={'fade-up'} data-aos-delay="600"  data-aos-offset="0">
                        Every day, I strive to learn something new. I ask myself... have I tried this yet? If I don't know how to do something, I teach myself and get it done.
                </p>
            </Hero>

            <ContentWrapper>
                <div className={styles.timeline}>
                    {buildContent()}
                </div>

            </ContentWrapper>

        </AnimationWrapper>
    )
};

export default Info
