import React from 'react';
import styles from './ContentWrapper.module.scss';

type ContentWrapperProps = {
    children: React.ReactNode
}

const ContentWrapper = ({children}: ContentWrapperProps) => {
    return <div className={styles.contentWrapper} data-testid={'wrapper'}>
        <section>{children}</section>
    </div>
};

export default ContentWrapper
