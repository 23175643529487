import ContentWrapper from "@Components/contentWrapper/ContentWrapper";
import PortfolioNav from "@Components/portfolioNav/PortfolioNav";
import AnimationWrapper from "@Layout/animationWrapper/AnimationWrapper";
import styles from './PortfolioLayout.module.scss';
import React from "react";

type PortfolioLayoutProps = {
    page: string,
    children: React.ReactNode
}

const PortfolioLayout = ({page, children}: PortfolioLayoutProps) => {

    return (
        <>
            <ContentWrapper>
                <PortfolioNav page={page}/>
            </ContentWrapper>
            <AnimationWrapper>
                <ContentWrapper>
                    <div className={styles.portfolioWrapper}>
                        {children}
                    </div>
                </ContentWrapper>
            </AnimationWrapper>
        </>
    )
};

export default PortfolioLayout
