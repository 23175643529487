import PortfolioLayout from "@Layout/portfolioLayout/PortfolioLayout";
import PortfolioAside from "@Components/portfolioAside/PortfolioAside";
import styles from './OriginalByDesign.module.scss'

const OriginalByDesign = () => {

    return (
        <PortfolioLayout page={'msft-obd'}>

            <section>

                <h3>Project Description</h3>

                <p>The website reflects a long-form version of the Microsft anthem video with four influencer profiles, and more. It's an award winning parallaxing site with rich animation and storytelling.</p>

                <h4 className={styles.tagline}>Original By ___________</h4>

                <div className={styles.video}>
                    <video autoPlay playsInline muted loop>
                        <source src={'/assets/portfolios/obd/OBD-preview-trimmed.mp4'} type="video/mp4" />
                    </video>
                </div>

                <p>We've created four short features about how our preferred influencers (Martellus Bennett, Cache Bunny, Brooke Shaden, Deadmau5) embody the “Original by design” ethos. Each influencers "Explore" section guides us through their creative process, highlighting where they draw inspiration from, how they use Surface in their work, and the influence they hope their work will have on the world.</p>

                <p>As a follow-up to our profile series, we’ve asked each of our influencers to participate in a game of telephone by creating an original piece of content inspired by the creative output of the person before them.</p>

                <p>The site also partners with DBrand for device skins, that can be visually customized via our device tool on the website.</p>

                <div className={styles.videoMobile}>
                    <video autoPlay playsInline muted loop>
                        <source src={'/assets/portfolios/obd/OBD-mobile.mp4'} type="video/mp4" />
                    </video>
                </div>

                <h3>OBD 2.0</h3>

                <p>There is a contest piece that was introduced as a 2 part launch. There is an interactive gallery of entries a user can explore on site. Upon completion of the contest we will have a 2.1 launch showing all the finalist in a similar grid and at 2.2 launch the page will only display the final 4 finalist.</p>

                <p>Total lines of handwritten code: 14,636</p>

                <div className={styles.award}>
                    <img src={'/assets/portfolios/obd/addy-award.png'} alt={'Addy Award Seattle 2022'}/>
                </div>

            </section>

            <PortfolioAside link={{url: 'https://msft-obd.web.app/', text: 'Microsoft OBD'}} list={['React','Node','Greensock Animations','Custom interactive gallery', 'Rigorously tested for accessibility ']}/>

        </PortfolioLayout>

    )
};

export default OriginalByDesign
