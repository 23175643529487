import PortfolioLayout from "@Layout/portfolioLayout/PortfolioLayout";
import PortfolioAside from "@Components/portfolioAside/PortfolioAside";
import styles from "@Pages/portfolio/wordGame/WordGame.module.scss";

const WordGame = () => {

    return (
        <PortfolioLayout page={'st'}>
            <section>
                <h3>Project Description</h3>
                <p>A client pitch / POC that I decided would still be worth building out for fun and mental exercise. A clone of a popular word game, with a spin off a popular streaming show.</p>
                <p>Try it out, have fun!</p>
                <h4 className={styles.label}>Game Demo</h4>
                <div className={styles.image}>
                    <a href={'https://strange-words-game.web.app/'} target="_blank" rel="noreferrer">
                    <img src={'/assets/portfolios/word/screen.png'} alt={'Word Game Design'}/>
                    </a>
                </div>
            </section>
            <PortfolioAside link={{url: 'https://strange-words-game.web.app/', text: 'Strange Word Game'}} list={['React','Custom SVG file (keyboard), manipulated by code']}/>

        </PortfolioLayout>
    )
};

export default WordGame
