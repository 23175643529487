
import portfolioPages from "@Pages/portfolio/Portfolio.pages";
import styles from './PortfolioNav.module.scss'
import {Link} from "react-router-dom";
import {motion} from "framer-motion";

type PortfolioNavProps = {
    page: string
}

const PortfolioNav = ({page}: PortfolioNavProps) => {
    
    let activePortfolioIndex = portfolioPages.findIndex((portfolio)=>portfolio.key === page) || 0;
    let prevLinkIndex = activePortfolioIndex === 0 ? portfolioPages.length-1 : activePortfolioIndex - 1;
    let nextLinkIndex = activePortfolioIndex === portfolioPages.length-1 ? 0 : activePortfolioIndex + 1;

    const pageMotion = {
        initial: { opacity: 0, x: -100 },
        animate: { opacity: 1, x: 0, transition: { duration: 0.5 } },
        exit: { opacity: 0, x: 100, transition: { duration: 0.5 } }
    };

    return (
        <nav className={styles.portfolioNav} data-testid={'portfolio-nav'}>
            <Link to={portfolioPages[prevLinkIndex].link}>&#9001;&#9001;</Link>
            <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageMotion}
                className={styles.animationWrapper}
            >
                <h1 dangerouslySetInnerHTML={{__html: portfolioPages[activePortfolioIndex].title}}/>
            </motion.div>
            <Link to={portfolioPages[nextLinkIndex].link}>&#9002;&#9002;</Link>
        </nav>
    )
};

export default PortfolioNav
