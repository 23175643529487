import PortfolioLayout from "@Layout/portfolioLayout/PortfolioLayout";
import PortfolioAside from "@Components/portfolioAside/PortfolioAside";
import styles from './ServiceAI.module.scss'

const ServiceAI = () => {

    return (
        <PortfolioLayout page={'ai'}>
            <section>
                <h3>Project Description</h3>
                <p>The Intel CCG AI tool discovery project seeks to make a collection of media assets searchable by content rather than by predefined tags. This is achieved through the use of AI tagging: the media is run through Microsoft's Azure Cognitive Services to generate a large quantity of metadata. This metadata is filtered and added to a search index. This is then used to make the media searchable through natural queries.</p>
                <div className={styles.video}>
                    <video autoPlay playsInline muted loop controls>
                        <source src={'/assets/portfolios/ai/click-through.mp4'} type="video/mp4" />
                    </video>
                </div>
                <p>This application allowed users to upload videos to their account, and search for videos based on tags, text and faces recognized during the process of Azure video indexing. It also allowed users to search transcripts created from the upload process. We were able to highlight sections of videos as well based on timestamps of keywords found during indexing.</p>
                <p>Part of the integration for this site was to create React components and styles that were easily shareable across all the applications. This allowed us to optimize much of our code by reducing code redundancy. The end result was an Electron application demonstrated internally at CES 2019 (non-keynote)</p>
            </section>
            <PortfolioAside link={{url: '', text: ''}} list={['React','React Native','Electron','Azure Cognitive Services']}/>
        </PortfolioLayout>
    )
};

export default ServiceAI
