import React, {useEffect,useRef,useCallback} from 'react';
import styles from './Hero.module.scss';

type HeroProps = {
    home?: boolean,
    frame: string,
    children?: React.ReactNode[]
}

const Hero = ({home, frame, children}: HeroProps) => {

    const bgImage = useRef<HTMLDivElement>(null);

    const isTouch = (e: TouchEvent | MouseEvent): e is TouchEvent => {
        return e.type === 'touch';
    }

    const mouseMove = useCallback((e: TouchEvent | MouseEvent) => {

        let moveLimit = 100;

        let w      = window.innerWidth;
        let h      = window.innerHeight;
        let center = { x: w/2, y: h/2 };

        if(e){

            let evX = isTouch(e) ? e.touches[0].clientX : e.clientX;
            let evY = isTouch(e) ? e.touches[0].clientY : e.clientY;

            let moveX = (center.x - evX) / 60;
            let moveY = (center.y - evY) / 60;

            moveX = (moveX > moveLimit) ? moveLimit : moveX;
            moveX = (moveX < moveLimit*-1) ? moveLimit*-1 : moveX;
            moveY = (moveY > moveLimit) ? moveLimit : moveY;
            moveY = (moveY < moveLimit*-1) ? moveLimit*-1 : moveY;

            if(bgImage.current) {
                bgImage.current.style.backgroundPosition = `calc(50% + ${ (Math.ceil(moveX))}px) calc(50% + ${(Math.ceil(moveY))}px)`
            }

        }

    }, []);

    useEffect(()=>{

        // let moveEvent = ('ontouchstart' in window) ? "touchmove" : "mousemove";
        let mouseMoveFunction = (e: TouchEvent | MouseEvent) => mouseMove(e);

        window.addEventListener('touchmove', mouseMoveFunction)
        window.addEventListener('mousemove', mouseMoveFunction)
        return () => {
            window.removeEventListener('touchmove', mouseMoveFunction);
            window.removeEventListener('mousemove', mouseMoveFunction);
        }

    },[mouseMove])

    return (
        <div className={`${styles.hero} ${home ? styles.home : ''}`}>
            <div data-testid={'image'} className={styles.frame} style={{backgroundImage: `url(${frame})`}} ref={bgImage}/>
            <div className={styles.details}>
                {children}
            </div>
        </div>
    )
};

export default Hero
