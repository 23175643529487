import {useEffect} from "react";
import {BrowserRouter} from "react-router-dom";
import styles from './App.module.scss'

import Body from "@Layout/body/Body";
import Header from "@Layout/header/Header";
import Footer from "@Layout/footer/Footer";

import 'aos/dist/aos.css';
import AOS from "aos";

function App() {

    useEffect(() => {
        AOS.init({
            delay: 200, // values from 0 to 3000, with step 50ms
            duration: 1000,
            once: true,
            offset: 0
        });
        // window.AOS = AOS;
    }, []);

    return (
        <BrowserRouter>
            <div className={styles.layoutWrapper}>
                <Header/>
                <Body/>
                <Footer/>
            </div>
        </BrowserRouter>
    );
}

export default App;
