import AnimationWrapper from "@Layout/animationWrapper/AnimationWrapper";
import styles from './Portfolio.module.scss';
import ContentWrapper from "@Components/contentWrapper/ContentWrapper";
import {Link} from 'react-router-dom';
import portfolioPages from "@Pages/portfolio/Portfolio.pages";

interface Page {
    key: string;
    link: string;
    label: string;
    image?: string;
    video?: string;
    demo?: boolean;
}

const Portfolio = () => {

    const pageLinks = (demoContent: boolean) => {
        return portfolioPages.map((page: Page)=>{

            if(demoContent === page.demo ){
                return (
                    <Link key={page.key} to={page.link} className={styles.link}>
                        {page.image && (
                            <div className={styles.background} style={{backgroundImage: `url(${page.image})`}}></div>
                        )}
                        {page.video && (
                            <video className={styles.video} autoPlay playsInline muted loop>
                                <source src={page.video} type="video/mp4" />
                            </video>
                        )}
                        <div className={styles.banner}  dangerouslySetInnerHTML={{__html:page.label}}/>
                    </Link>
                )
            }

            return null;
            
        })
    }

    return (
        <AnimationWrapper>

            <ContentWrapper>

                <h1>Portfolio</h1>

                <div className={styles.portfolioWrapper}>
                    {pageLinks(false)}
                </div>

                <h1>Fun Demos</h1>

                <div className={styles.portfolioWrapper}>
                    {pageLinks(true)}
                </div>


            </ContentWrapper>

        </AnimationWrapper>
    )
};

export default Portfolio
