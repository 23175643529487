import PortfolioLayout from "@Layout/portfolioLayout/PortfolioLayout";
import PortfolioAside from "@Components/portfolioAside/PortfolioAside";
import styles from './SpaceJam.module.scss'

const SpaceJam = () => {

    return (
        <PortfolioLayout page={'msft-sj'}>
            <section>
                <h3>Project Description</h3>
                <p>Space Jam 2, also known as Space Jam: A New Legacy, is an upcoming live action/ animated sports comedy film directed by Malcolm D. Lee, and starring NBA Superstar, LeBron James. The highly anticipated sequel sees LeBron James stepping into Michael Jordan’s previous role, alongside many of your favorite Looney Tune characters (Buggs Bunny, Lola Bunny, etc.).</p>
                <div className={styles.video}>
                    <video autoPlay playsInline muted loop controls>
                        <source src={'/assets/portfolios/sj/game-play.mp4'} type="video/mp4" />
                    </video>
                </div>
                <p>Users go to a dedicated page at <a href={'https://inculture.microsoft.com/space-jam-a-new-legacy/save-the-tunes/'}>Microsoft.com</a>, and complete four challenge. Each challenge will tie back to both Surface and Space Jam: A New Legacy brands. Completing all four challenges gives users the chance to enter to win a Grand Prize.</p>
                <div className={styles.video}>
                    <video autoPlay playsInline muted loop>
                        <source src={'/assets/portfolios/sj/final-animation.mp4'} type="video/mp4" />
                    </video>
                </div>
                <div className={styles.imageRow}>
                    <img src={'/assets/portfolios/sj/mobile1.png'} alt={'Daffy Game Screen'}/>
                    <img src={'/assets/portfolios/sj/mobile2.png'} alt={'Cloud View'}/>
                    <img src={'/assets/portfolios/sj/mobile3.png'} alt={'Lola Screen'}/>
                    <img src={'/assets/portfolios/sj/mobile4.png'} alt={'Bugs Screen'}/>
                </div>

            </section>

            <PortfolioAside link={{url: 'https://msft-sj.web.app/', text: 'Microsoft Space Jam'}} list={['React','Greensock Animations','Custom SVG manipulations', 'Custom character animation', 'Audio/video integration, with instant audio feedback in games','Canvas mouse tracking / svg line tracing', 'Rigorously tested for accessibility']}/>

        </PortfolioLayout>
    )
};

export default SpaceJam
