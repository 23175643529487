import styles from './PortfolioAside.module.scss';
import React from "react";

type PortfolioAsideProps = {
    link?: {
        url: string,
        text: string
    },
    list: string[]
}

const PortfolioAside = ({link,list}: PortfolioAsideProps) => {

    const buildLink = () => {
        if(link) return <div>Visit: <a href={link.url} target="_blank" rel="noreferrer">{link.text}</a></div>
    }

    const buildList = () => {

        let listDisplay = list.map((item, itemIndex)=>{
            return <li key={itemIndex}>{item}</li>
        })

        return <ul>{listDisplay}</ul>
    }

    return (
        <aside className={styles.aside} data-testid={'portfolio-aside'}>
            <h4>Details</h4>
            {buildLink()}
            {buildList()}
        </aside>
    )
};

export default PortfolioAside
