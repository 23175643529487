import PortfolioLayout from "@Layout/portfolioLayout/PortfolioLayout";
import PortfolioAside from "@Components/portfolioAside/PortfolioAside";
import styles from './CmdAgency.module.scss';

const CmdAgency = () => {

    return (
        <PortfolioLayout page={'cmd'}>
            <section>

                <h3>Project Description</h3>
                <p>The CMD Agency website is the agencies showpiece for the creative work done by our multi-talented teams. For myself it was an exploration into server side generation using React and Next.js to fetch site content from a headless Wordpress backend.</p>
                <div className={styles.imageRow}>
                    <div className={styles.image_1}><img src={'/assets/portfolios/cmd/screen-desktop.png'} alt={'Desktop Screen'}/></div>
                    <div className={styles.image_2}><img src={'/assets/portfolios/cmd/screen-mobile.png'} alt={'Mobile Screen'}/></div>
                </div>
                <p>We decided to test using wordpress as a headless CMS for a proof of concept internally, and by utilizing the Advanced Custom Fields plugin we were able to define schemas for individual, reusable components throughout the site. This allowed for page by page block building giving our designers more flexibility and the ability to create pages using blocks they have created, once supporting React components were built. By integrating Next.js’s Static Incremental Generation we could create full static builds, that will rebuild the content when it detects data in the CMS changed.</p>
                <div className={styles.imageRow}>
                    <div className={styles.image_3}><img src={'/assets/portfolios/cmd/screen1.jpg'} alt={'Home Page View'}/></div>
                    <div className={styles.image_4}><img src={'/assets/portfolios/cmd/screen2.jpg'} alt={'Services Page View'}/></div>
                </div>
            </section>

            <PortfolioAside link={{url: 'https://cmdagency.com', text: 'CMD Agency Site'}} list={['React','Headless Wordpress','ACF Flexible Content','WPGraphQL','Next.js']}/>


        </PortfolioLayout>
    )
};

export default CmdAgency
