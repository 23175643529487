import {Link} from "react-router-dom";
import styles from './Header.module.scss';
import logo from '@Assets/images/logo.png';
import {useState} from "react";

const Header = () => {

    const [navOpen, setNavOpen] = useState(false)

    return (
        <header className={styles.header}>
            <Link to={'/'} className={styles.home}>
                <img className={styles.logo} src={logo} alt={'Jeremy Ingle'}/>
                <div>
                    <div className={styles.title}>JEREMY<span>INGLE</span></div>
                    <div className={styles.subTitle}>PROFESSIONAL SOFTWARE ENGINEER</div>
                </div>

            </Link>
            <span className={styles.spacer}/>
            <span className={`${styles.icon} ${navOpen ? styles.active : ''}`}
                  onMouseOver={(el)=>{  el.currentTarget.classList.add(styles.hover) }}
                  onMouseOut={(el)=>{ el.currentTarget.classList.remove(styles.hover) }}
                  onClick={()=>setNavOpen(!navOpen)}
            >
                <svg viewBox="0 0 30 20" width="30" height="30">
                  <rect width="30" height="2" rx="2"/>
                  <rect y="8" width="30" height="2" rx="2"/>
                  <rect y="16" width="30" height="2" rx="2"/>
                </svg>
            </span>
            <nav className={navOpen ? styles.open : styles.closed} onClick={()=>setNavOpen(false)}>
                {navOpen && <Link to={'/'}>Home</Link>}
                <Link to={'/about'}>About</Link>
                <Link to={'/portfolio'}>Portfolio</Link>
                <Link to={'/info'}>Info</Link>
                <Link to={'/contact'}>Contact</Link>
            </nav>
        </header>
    )

};

export default Header
