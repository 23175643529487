import AnimationWrapper from "@Layout/animationWrapper/AnimationWrapper";
import styles from './Home.module.scss';
import {Link} from "react-router-dom";
import Title from "@Components/title/Title";
import IconCopy from "@Components/iconCopy/IconCopy";

import IconAbout from '@Assets/images/icon_about.png';
import IconInfo from '@Assets/images/icon_info.png';
import IconPortfolio from '@Assets/images/icon_portfolio.png';
import IconContact from '@Assets/images/icon_contact.png';
import Hero from "@Components/hero/Hero";
import heroImage from '@Assets/images/portrait.jpg';
import ContentWrapper from "@Components/contentWrapper/ContentWrapper";

const Home = () => {

    return (

        <AnimationWrapper>

            <Hero frame={heroImage}>
                <h1 data-aos={'fade-up'} data-aos-delay="200">Software Engineer - Architect - Designer</h1>
                <h2 data-aos={'fade-up'} data-aos-delay="400">Jeremy Ingle</h2>
                <p data-aos={'fade-up'} data-aos-delay="600"  data-aos-offset="0">
                    I’m a principal software engineer specializing in building and architecting exceptional digital experiences. Currently, I’m focused on building accessible, human-centered products at CMD.
                </p>
                <div data-aos={'fade-up'} data-aos-delay="800" className={styles.homeButtons}>
                    <a target={'_blank'} rel="noreferrer" href={'assets/docs/Resume.docx'}>Download CV</a>
                    <Link to={'/contact'}>Contact</Link>
                </div>
            </Hero>


            <ContentWrapper>
                <Title text={'What I have to offer'} tag={'h3'}/>
                <div className={styles.offeringWrapper}>

                    {/*Work with a variety of different languages, platforms, frameworks, and content management systems such as JavaScript, TypeScript, Gatsby, React, Craft, WordPress, Prismic, and Netlify*/}

                    <div>
                        <IconCopy icon={IconAbout}
                                  copy={"As a software engineer, I enjoy the day-to-day challenges of mobile, web and application development. My focus is building quality, user centric experiences online."}/>
                        <IconCopy icon={IconInfo}
                                  copy={" I have over two decades of experience, including end-to-end software development using modern tools like React and Node, while working in multi-disciplinary teams of engineers, designers, producers, and clients on a daily basis."}/>
                        <IconCopy icon={IconPortfolio}
                                  copy={"I have an in-depth knowledge and first hand experience in mobile first responsive design implementations, with extensive knowledge of cross-browser and platform differences, including mobile devices."}/>
                        <IconCopy icon={IconContact}>
                            <p>There's nothing better than collaborating with quality individuals. If you wanna get in touch, talk to me about a project collaboration or just say hi, fill out my contact form <Link to={'/contact'}>here</Link>.</p>
                        </IconCopy>
                    </div>
                </div>
            </ContentWrapper>

        </AnimationWrapper>
    )
};

export default Home
