import styles from './IconCopy.module.scss';
import React from "react";

type IconCopyProps = {
    icon: string,
    copy?: string,
    children?: React.ReactNode
}

const IconCopy = ({icon,copy,children}: IconCopyProps) => {

    return (
        <div className={styles.iconCopy}>
            <div className={styles.icon}>
                <img data-testid={'icon'} src={icon} alt={'icon'}/>
            </div>
            {copy && <p>{copy}</p>}
            {children}
        </div>
    )
};

export default IconCopy
