import React from "react";
import styles from './Title.module.scss';

type TitleProps = {
    text: string,
    tag?: keyof JSX.IntrinsicElements
}

const Title = ({text, tag: ElementType = 'h2' }: TitleProps) => {

    return (
        <ElementType>
            {text}
            <span className={styles.accent} data-testid={'title'}/>
        </ElementType>
    )
};

export default Title
